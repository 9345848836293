import {formatNumber} from '../utils/commonfunctions';

import React, {useState} from 'react';
import {useEffectOnce} from 'react-use';
import {useTranslation} from 'react-i18next';

function Level(props) {
  const {t} = useTranslation();
  const [data, setData] = useState(props.data);

  useEffectOnce(() => {
    setData({
      monastery: +props.data.monastery,
      confirmed: +props.data.confirmed,
      recovered: +props.data.recovered,
      deaths: +props.data.deaths,
      deltaconfirmed: +props.data.deltaconfirmed,
      deltadeaths: +props.data.deltadeaths,
      deltarecovered: +props.data.deltarecovered,
    });
  });

  return (
    <div className="Level">
      {/* <div
        className="level-item is-cherry fadeInUp"
        style={{animationDelay: '1s'}}
      >
        <h5>{t('totalSangha.full')}</h5>
        <h4>
          [
          {isNaN(data.deltaconfirmed)
            ? ''
            : data.deltaconfirmed > 0
            ? '+' + formatNumber(data.deltaconfirmed)
            : '+0'}
          ]
        </h4>
        <h1>{formatNumber(data.confirmed)} </h1>
      </div> */}

      <div
        className="level-item is-blue fadeInUp"
        style={{animationDelay: '1.1s'}}
      >
        <h5 className="heading">{t('monastery.full')}</h5>
        <h4>&nbsp;</h4>
        <h1 className="title has-text-info">{formatNumber(data.monastery)}</h1>
      </div>
    </div>
  );
}

export default React.memo(Level);
